<template>
<div>
  <div class="full-width category-container">
    <div class="container">
      <div class="col-3 category-filters">

        <div class="single-filter">
          <router-link to="/brand/Altromercato" class="filter-name neue">Altromercato</router-link>
          <router-link to="/brand/Natyr" class="filter-name neue">Natyr</router-link>
          <router-link to="/brand/ON EARTH" class="filter-name neue">ON EARTH</router-link>
          <router-link to="/brand/Solidale Italiano" class="filter-name neue">Solidale Italiano</router-link>
          <router-link to="/brand/Bio Natyr" class="filter-name neue">Bio Natyr</router-link>
          <router-link to="/brand/Auteurs du monde" class="filter-name neue">Auteurs du monde</router-link>
          <router-link to="/brand/Libera Terra" class="filter-name neue">Libera Terra</router-link>
          <router-link to="/brand/Altri marchi Solidali" class="filter-name neue">Altri marchi Solidali</router-link>
        </div>

      </div>

      <div class="col-9 category-content">
        <div v-if="path.length">
          <Breadcrumbs :path="path" :key="childKey" />
        </div>

        <h2 class="neue">{{$route.params.name}}</h2>

        <CategoryView code="$route.params.name" :key="childKey" :isBrand="true" />
      </div>
    </div>
  </div>

  <div class="full-width gray-spacer"></div>
  <div class="full-width hero lesser">
    <div class="col-7">
      <img src="@/assets/home2.png" class="cover-img" />
    </div>
    <div class="col-5 center-content">
      <div class="button-square m-b-5">I nostri valori</div>
      <p>Design made in Italy e<br/><b>valori fatti a mano</b></p>
      <div class="button">scopri perch&eacute;</div>
    </div>
  </div>
  <div class="full-width gray-spacer"></div>
</div>
</template>

<script>
import Breadcrumbs from '@/components/Breadcrumbs'
import CategoryView from '@/components/category/View'

export default {
  name: 'Category',
  data: function () {
    return {
      path: [
        { label: 'Home', path: '/' },
        { label: this.$route.params.name, path: '/' },
      ],
      childKey: 0
    }
  },
  watch: {
    $route() {
      this.childKey++
      this.path = [
        { label: 'Home', path: '/' },
        { label: this.$route.params.name, path: '/' },
      ]
      this.forceUpdate()
    }
  },
  mounted () { },
  components: {
    Breadcrumbs,
    CategoryView
  },

  methods: { }
}
</script>

<style scoped>
.category-container {
  margin-top: 38px;
  padding-bottom: 60px;
}

.category-content h2 {
  font-size: 38px;
  font-weight: 600;
  letter-spacing: 0.4px;
  margin: 0 0 20px 0;
}

.category-content p {
  margin: 0 0 25px 0;
  max-width: 66.66666%;
  line-height: 140%;
  font-size: 14px;
  font-weight: 500;
}

.single-filter {
  width: 100%;
  padding-right: 50px;
}

.single-filter .filter-name {
  display: block;
  font-weight: 800;
  font-size: 13px;
  margin-bottom: 20px;
  text-transform: uppercase;
  position: relative;
  height: 25px;
  line-height: 25px;
  margin-top: 0;
  cursor: pointer;
  letter-spacing: 0.5px;
}

.single-filter .filter-name:after {
  content: '';
  position: absolute;
  top: -1px;
  right: 0;
}

.single-filter .filter-content {
  padding-left: 40px;
  margin-bottom: 40px;
}

.single-filter .filter-content a {
  display: block;
  font-weight: 500;
  font-weight: 13px;
  margin-bottom: 12px;
}

@media (max-width: 680px) {
  .single-filter {
    display: none;
  }
}
</style>

